<template>
  <div class="content-center" id="discount-slabs-list-view">

    <!-- BREADCRUMB -->
    <breadcrumb-base :breadcrumb="breadcrumb" :title="'DiscountSlabs'">
      <template v-slot:right-end>
        <div class="vx-row">
                    <vs-button class="alpha-clear" v-if="selectedAlpha" color="warning" type="flat" icon-pack="feather" icon="icon-x" size="small" @click="clearSelectedAlpha()"></vs-button>
                    <vs-pagination-modified v-model="selectedAlphaCurrentX" :total="26" :max="26" v-on:change="pageChanged"></vs-pagination-modified>
          <div class="p-3"></div>
          <vs-button @click="openAddDiscountSlab" type="filled" icon-pack="feather"
                     icon="icon-plus">{{ $t('AddDiscountSlab') }}
          </vs-button>
        </div>
      </template>
    </breadcrumb-base>

    <!--    Empty List State -->
    <transition name="fade">
      <div class="py-6 flex w-full bg-img" v-if="discountSlabs.length === 0 && !errorFetching">
        <div
          class="vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto text-center">
<!--          <img src="@/assets/images/pages/svg_blank.svg" :alt="$t('NoData')"-->
<!--               class="mx-auto mb-4 max-w-full">-->
          <h1 class="sm:mx-0 mx-4 mb-12 text-3xl">{{ $t('NoData') }}</h1>
        </div>
      </div>
    </transition>

    <!--    Error List State-->
    <transition name="fade">
      <div class="py-6 flex w-full bg-img" v-if="errorFetching">
        <div
          class="vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto text-center">
          <img src="@/assets/images/pages/svg_error.svg" :alt="$t('NoData')"
               class="mx-auto mb-4 max-w-full">
          <h1 class="sm:mx-0 mx-4 mb-12 text-3xl">{{ $t('ErrorData') }}</h1>
        </div>
      </div>
    </transition>


    <div class="vs-col px-4 py-3 flex md:w-full">
      <div class="vs-row w-full">
        <div class="vs-col px-3 py-3 flex md:w-full">
          <div class="vs-row w-full" >
            <transition name="fade">
              <div class="h-screen flex w-full bg-img" v-if="discountSlabs.length === 0 && !errorFetching">
                <div
                  class="vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto text-center">
<!--                  <img src="@/assets/images/pages/svg_blank.svg" :alt="$t('NoData')"-->
<!--                       class="mx-auto mb-4 max-w-full">-->
                  <h1 class="sm:mx-0 mx-4 mb-12 text-3xl">{{ $t('NoData') }}</h1>
                </div>
              </div>
            </transition>
            <div class="vs-col md:w-1/3 pr-6 mb-6" v-bind:key="index" v-for="(discount, index) in discountSlabs">
              <vx-card class="cursor-pointer" style="" @click="openProductDiscounts(discount)">
                <p class="card-text">{{discount.plan_name}}</p>
                <!--                    <p class="card-text-amount">{{product.AMOUNT | germanNumberFormat}}</p>-->
              </vx-card>
            </div>
          </div>
        </div>
      </div>
    </div>
<!--    -->
<!--    <div class="vx-row w-full mb-base c-center flex items-center justify-around">-->
<!--      <div class="w-full flex">-->

<!--        <vs-table class="w-full" v-show="discountSlabs.length > 0 && !errorFetching" ref="table" pagination-->
<!--                  :max-items="10" search-->
<!--                  :data="discountSlabs"-->
<!--                  @selected="openProductDiscounts">-->

<!--          <template slot="thead">-->
<!--            <vs-th >{{ $t('PlanId') }}</vs-th>-->
<!--            <vs-th >{{ $t('PlanName') }}</vs-th>-->
<!--            <vs-th >{{ $t('Discount') }}</vs-th>-->
<!--          </template>-->

<!--          <template slot-scope="{data}">-->
<!--            <tbody>-->
<!--            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">-->

<!--              <vs-td>-->
<!--                <p class="font-medium truncate">{{ tr.plan_id }}</p>-->
<!--              </vs-td>-->
<!--              <vs-td>-->
<!--                <p class="font-medium truncate">{{ tr.plan_name }}</p>-->
<!--              </vs-td>-->
<!--              <vs-td>-->
<!--                <p class="font-medium truncate">{{ tr.plan_discount }}</p>-->
<!--              </vs-td>-->

<!--            </vs-tr>-->
<!--            </tbody>-->
<!--          </template>-->
<!--        </vs-table>-->
<!--      </div>-->
<!--    </div>-->
  </div>
</template>

<script>
export default {
  name: 'ViewDiscountSlabs',
  computed: {
    discountSlabs() {
      if (this.selectedAlpha == '' || this.selectedAlpha == null) {
        return this.$store.state.pad.discountSlabs
      }
      return this.$store.state.pad.discountSlabs.filter((s) => s.plan_name.startsWith(this.selectedAlpha))
    },
  },
  data() {
    return {
      isMounted: false,
      errorFetching: false,
      selectedAlpha: '',
      selectedAlphaCurrentX: '',
      alpha: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
      breadcrumb: [
        { title: 'Home', i18n: 'Home', url: '/' },
        { title: 'DiscountSlabs', i18n: 'DiscountSlabs', active: true },
      ],
    }
  },
  methods: {
    clearSelectedAlpha() {
      this.selectedAlpha = null
      this.selectedAlphaCurrentX = 1
    },
    openProductDiscounts(d) {
      this.$router.push({ name: 'product-discount-slabs', params: { discountSlabId: d.plan_id } })
    },
    openAddDiscountSlab() {
      this.$router.push({ name: 'discount-slabs-add' })
    },
    pageChanged(newIndex) {
      if (isNaN(newIndex)) {
        this.selectedAlpha = newIndex
        this.selectedAlphaCurrentX = newIndex
      }
    },
    fetchDiscountSlabs() {
      this.$vs.loading()
      return this.$store.dispatch('pad/fetchDiscountSlabs')
        .then((data) => {
          this.$vs.loading.close()
        })
        .catch((error) => {
          this.$vs.loading.close()
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: $t('ErrorDiscountSlabs'),
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
  },
  created() {
    this.fetchDiscountSlabs()
  },
}
</script>

<style lang="scss">
#discount-slabs-list-view {

  .card-text {
    /*max-height: 48px;*/
    object-fit: cover;
    margin: auto;
    font-size: 18px;
    font-weight: bold;
  }

  .vs-con-table {

    /*
      Below media-queries is fix for responsiveness of action buttons
      Note: If you change action buttons or layout of this page, Please remove below style
    */
    @media (max-width: 689px) {
      .vs-table--search {
        margin-left: 0;
        max-width: unset;
        width: 100%;

        .vs-table--search-input {
          width: 100%;
        }
      }
    }

    @media (max-width: 461px) {
      .items-per-page-handler {
        display: none;
      }
    }

    @media (max-width: 341px) {
      .customer-list-btn-container {
        width: 100%;

        .dd-actions,
        .btn-add-new {
          width: 100%;
          margin-right: 0 !important;
        }
      }
    }

    .supplier-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap;
      margin-left: 1.5rem;
      margin-right: 1.5rem;

      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, .05);

        td {
          padding: 20px;

          &:first-child {
            border-top-left-radius: .5rem;
            border-bottom-left-radius: .5rem;
          }

          &:last-child {
            border-top-right-radius: .5rem;
            border-bottom-right-radius: .5rem;
          }
        }

        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }

      th.td-check {
        padding: 0 15px !important;
      }

      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
